<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" data-projection-id="134" style="opacity: 1">
        <div data-testid="hero-image-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <img style="width: 100%; height: 100%;" :src="require('@/assets/images/awards/b1.png')" alt="" />
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-300 dark:text-iblack-300 max-w-[500px]">
                ZEN CAPITAL Impact Awards
              </h1>
              <p class="text-lg text-iblack-300 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                Our awards, run in partnership with Lever for Change, enable members of the ZEN CAPITAL community to identify
                philanthropic opportunities through open, transparent, and rigorously-evaluated challenges that address
                some of the greatest social issues of our time.
              </p>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              data-projection-id="135"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="136">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="137"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/awards/b2.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="138"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Larsen Lam ZEN CAPITAL Impact Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="139"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            The Larsen Lam ZEN CAPITAL Impact Award is a $24 million grant competition supporting
                            organizations improving the lives of refugees around the world.
                          </p>
                          <div
                            class="mt-22"
                            data-projection-id="140"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            <span
                              class="inline-flex items-center button-target transition-opacity duration-400 opacity-100 hover:opacity-80"
                              ><span
                                ><img style="width: 24px; height: 24px;" :src="require('@/assets/images/awards/right-circle.png')" alt="" /></span>
                              <span
                                class="text-iblack-700 dark:text-iwhite-100 md:text-iblack-700 dark:text-iwhite-100 ml-3"
                              >
                                Meet the Awardees
                              </span></span
                            >
                          </div>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="141">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="142"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/awards/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="143"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Stronger Democracy Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="144"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            The Stronger Democracy Award is a $22 million grant competition to help improve political
                            representation and increase participation in the United States’ democratic process.
                          </p>
                          <div
                            class="mt-22"
                            data-projection-id="145"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            <span
                              class="inline-flex items-center button-target transition-opacity duration-400 opacity-100 hover:opacity-80"
                              ><span
                                ><img style="width: 24px; height: 24px;" :src="require('@/assets/images/awards/right-circle.png')" alt="" /></span>
                              <span
                                class="text-iblack-700 dark:text-iwhite-100 md:text-iblack-700 dark:text-iwhite-100 ml-3"
                              >
                                Meet the Awardees
                              </span></span
                            >
                          </div>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="146">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="147"
                        style="   transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/awards/b4.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="148"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            Maternal &amp; Infant Health Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="149"
                            style="   transform: translateY(20px) translateZ(0px)"
                          >
                            The Maternal &amp; Infant Health Award is a $10 million grant competition supporting
                            organizations improving maternal and infant health outcomes around the world.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
